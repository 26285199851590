.body-checkout {
    min-height: 100vh;
    .container-fluid {
        max-width: 1130px;
    }
}

.checkout {
    .btn-checkout {
        text-transform: uppercase;
        font-size: 11px;
    }
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 25px;
        height: 25px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 2px;
        border: 3px solid #999;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #999 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .checkout-load-after {
        background-color: #fff3cd;
        border-color: #ffeeba;
        border-radius: 2px;
        padding: 10px;
        position: relative;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            margin-left: 18px;
            font-size: .9rem;
            line-height: 1rem;
        }
    }
    .container-fluid {
        max-width: 1130px;
    }
    .form-group {
        margin-bottom: 0.1rem;
    }
    .form-control {
        height: 38px;
    }
    .cidade-estado {
        display: flex;
        align-items: flex-end;
        font-size: .9rem;
        line-height: 1.2rem;
    }
    .correios {
        margin-bottom: .4rem;
        &-holder {
            margin-top: 0.8rem;
            h5 {
                font-size: 1rem;
            }
        }
        &--check {
            border: 1px solid #cfcbcb;
            background-color: #f5f5f5;
            border-radius: 2px;
            padding: 0.5rem 0 0.5rem 1.8rem;
            margin-bottom: 8px;
            &:last {
                margin-bottom: 0;
            }
            &--info {
                font-weight: 600;
                small {
                    margin-top: 5px;
                }
            }
        }
    }
    &__btn {
        margin-top: 0.5rem;
    }
    &__header {
        background-color: #383838;
        padding: 0.8rem 0;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        &__lock {
            color: #fff;
            text-align: right;
            a {
                padding-left: 17px;
                img {
                    margin-left: 5px;
                }
            }
            i {
                font-size: 32px;
                float: left;
            }
            h2 {
                text-align: right;
                margin: 0;
                font-size: .9rem;
                font-weight: 600;
                display: inline-block;
                span {
                    font-weight: 300;
                    line-height: .7rem;
                    font-size: .7rem;
                    display: block;
                }
            }
        }
    }
    .order {
        background-color: #fff;
        @include BoxShadow(3);
        border-radius: 5px;
        color: #646464;
        font-size: 1rem;
        padding: 15px;
        &__info {
            background-color: #F4F6F8;
            border-radius: 4px;
            padding: 1rem;
            font-weight: 500;
            .order-values {
                letter-spacing: 0.8px;
                color: #646464;
                font-size: 14px;
                line-height: 22px;
                .push-right {
                    text-align: right;
                }
            }
            .total {
                letter-spacing: 0.8px;
                margin-top: 7px;
                color: #646464;
                font-size: 14px;
                line-height: 22px;
                .sum {
                    font-weight: 800;
                    text-align: right;
                    font-size: 1.1rem;
                    #span-remove-frete {
                        font-size: 0.9rem;
                    }
                }
            }
        }
        .item {
            border-bottom: 1px solid #eee;
            padding: 20px;
            &:last-of-type {
                margin-bottom: 1rem;
                border-color: transparent;
            }
            .item-description {
                font-size: 0.9rem;
                color: #039DE2;
                span {
                    display: block;
                    color: #646464;
                    font-size: .8rem;
                    line-height: 0.9rem;
                }
            }
            .qtde {
                margin: 10px 0;
                font-size: .8rem;
                line-height: .9rem;
                span {
                    display: block;
                    font-size: .7rem;
                }
            }
            .price {
                line-height: 8px;
                font-size: 15px;
                font-weight: 800;
            }
        }
    }
    .steps {
        background-color: #fff;
        @include BoxShadow(3);
        border-radius: 5px;
        color: #646464;
        font-size: 1rem;
        margin-bottom: 20px;
        padding: 15px;
        #edit-dados {
            display: none;
        }
        &--header {
            margin-bottom: 15px;
        }
        .opened {
            display: none;
        }
        .closed {
            padding: 30px 10px;
            text-align: center;
        }
        label.destination {
            font-size: 12px;
            margin-left: 5px;
            margin-bottom: 0px;
            font-weight: 600;
        }
        &__update {
            margin-bottom: .5rem;
        }
        .section--address {
            background-color: #F4F6F8;
            border-radius: 4px;
            padding: 0.75rem;
            font-size: .95rem;
            line-height: 1.4rem;
            margin-bottom: 5px;
        }
        .infos {
            display: none;
            position: relative;
            &--minify {
                .information {
                    margin-top: -15px;
                }
            }
            &__item {
                // display: block;
                font-size: 0.8rem;
                line-height: 1.2rem;
                &.bold-text {
                    font-weight: 800;
                }
            }
            .edit-action {
                color: #039DE2;
                position: absolute;
                top: 0px;
                right: 17px;
                i {
                    font-size: 14px;
                }
                font-size: 12px;
                a {
                    font-size: 11px;
                    color: #039DE2;
                    border-bottom: 1px solid #039DE2;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .angle-down-down-down {
            position: absolute;
            top: 15px;
            right: 20px;
        }
        .check-success {
            position: absolute;
            top: 15px;
            right: 15px;
        }
        .check-error {
            position: absolute;
            top: 15px;
            right: 15px;
            color: #f75757!important;
        }
        .load-spinner {
            color: $alert-color-success-1;
            position: absolute;
            top: 15px;
            right: 15px;
        }
        input[type=radio] {
            width: 20px;
            height: 20px;
        }
        input[type=text] {
            text-transform: capitalize;
            &.success {
                border: 1px solid $alert-color-success-1;
            }
            &.error {
                border: 1px solid #ff3c3c;
            }
        }
        .select-arrow {
            position: absolute;
            top: 1rem;
            right: 17px;
            font-size: 20px;
        }
        .my-checkbox {
            margin: 0px 0px 0px -25px;
        }
        select {
            background-repeat: no-repeat;
            padding-left: 10px!important;
            margin: 0;
            border-radius: 4px;
            padding-left: 5px;
            border: 1px solid #c8c8c8;
            width: 100%;
            height: 45px;
        }
        h4 {
            font-size: 1rem;
            font-weight: 900;
            margin-bottom: 0;
            span {
                display: inline-block;
                width: 21px;
                height: 21px;
                border-radius: 21px;
                background-color: #00b881;
                margin-right: 5px;
                text-align: center;
                line-height: 21px;
                font-size: .8rem;
                color: #fff;
            }
        }
        span.msg {
            display: block;
            font-size: 12px;
        }
        span {
            &.email {
                font-size: 12px;
                font-weight: 800;
                line-height: 20px;
                margin-top: 30px;
            }
        }
        .ml-10 {
            margin-left: 10px;
        }
        .check {
            margin-left: 20px;
            float: left;
        }
        .news {
            margin-left: 3px;
        }
        .identity {
            width: 100%;
            text-align: center;
            padding: 50px;
            position: relative;
            transition: left 2s linear;
            span.head {
                margin-top: 10px;
                margin-left: 10px;
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
    }
}

.vcenter-item {
    display: flex;
    align-items: center;
}

section#checkout {
    .form-group {
        label {
            margin-bottom: 2px;
            font-size: 0.9rem;
        }
    }
    .form-control {
        color: #111;
        &::-webkit-input-placeholder {
            color: #bbb;
        }
    }
    input[type="text"]:read-only {
        background-color: #ccc;
    }
    .dirty {
        border-color: #5A5;
        background: #EFE;
    }
    .dirty:focus {
        outline-color: #8E8;
    }
    .error {
        border-color: red;
        background: #FDD;
    }
    .error:focus {
        outline-color: #F99;
    }
    .container {
        max-width: 933px;
    }
    body {
        background-color: #F1F1F1;
    }
    h1 {
        font-size: 18px;
        color: #646464;
    }
    .bb-2-s {
        border-bottom: 2px solid #C8C8C8;
    }
    .pd-15 {
        padding: 15px;
    }
    .mt-0 {
        margin-top: 0px;
    }
    .form-check {
        font-size: 12px;
    }
    .bg-white {
        background-color: #fff;
    }
    .form-check-label {
        line-height: 13px;
        small {
            font-size: .8rem;
            display: block;
        }
    }
    button.more-products {
        border-radius: 5px;
        border: 2px solid #BCBCBC;
        float: left;
        font-size: 1rem;
        letter-spacing: 0.8px;
        padding: 0px 30px;
        font-size: 10px;
        height: 45px;
    }
    button.go-to-payment {
        border-radius: 5px;
        border: 2px solid $blue;
        background-color: $blue;
        float: right;
        font-size: 1rem;
        letter-spacing: 0.8px;
        padding: 0px 30px;
        font-size: 10px;
        height: 45px;
        color: #fff;
    }
    .left-column {
        min-height: 100%;
    }
    .right-column {
        min-height: 100%;
    }
    .min-height-100vh {
        min-height: 100vh;
    }
    .mt-20 {
        margin-top: 20px;
    }
    .p-0-20 {
        padding: 0 20px;
        width: 100%;
        padding: 15px 30px;
    }
    .method-active {
        border: 1px solid #c7ffc7;
        background-color: #c7ffc7;
        //box-shadow: 5px 5px rgba(21, 21, 21, 0.12);
        /* margin-right: 72px; */
    }
    .t-31 {
        top: 31px!important;
    }
    .mt-30 {
        margin-top: 30px;
    }
    .ml-5 {
        margin-left: 5px;
    }
    .br-2-s {
        border-right: 2px solid #C8C8C8;
    }
    h3 {
        color: #646464;
        font-size: 21px;
        margin-left: 5px;
    }
    .delivery-price {
        font-size: 14px;
        font-weight: 800;
    }
    .coupom {
        margin: 20px 0px 0px;
        input {
            border: 2px solid #BCBCBC;
            border-radius: 5px 0 0 5px;
            height: 45px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-left: 0px!important;
            background-color: transparent;
            color: #646464;
            font-size: 11px;
        }
        button {
            border-radius: 0px 5px 5px 0px;
            background-color: #303030;
            border: 1px solid #303030;
            height: 45px;
            color: #E9E9E9;
        }
    }
    form.coupom {
        border: none;
    }
    .about-payment {
        padding: 1rem;
        width: 75%;
    }
    .btn-go-to-ship {
        background: $blue;
        padding: 5px 15px;
        color: #fff;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 10px;
        font-size: 12px;
        height: 45px;
        // opacity: 0.8;
        &:hover {
            opacity: 0.8;
        }
    }
    #submit-payment.btn-go-to-ship {
        background: $alert-color-success-1!important;
    }
    .form-check-label {
        padding: 2px;
        text-transform: uppercase;
        .correios--check--info {
            padding-left: .6rem;
        }
    }
    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter,
    .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
    .dn {
        display: none;
    }
    .db {
        display: block;
    }
    .fz-10 {
        font-size: 10px;
    }
    .mb-10 {
        margin-bottom: 10px;
    }
    .get-cupom {
        #cupom {
            text-transform: uppercase;
        }
        #remove-cupom {
            display: none;
        }
        &.has-cupom {
            #cupom {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
            #submit-cupom {
                display: none;
            }
            #remove-cupom {
                display: block;
            }
        }
    }
    .loader-area {
        height: 73px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        .spinner {
            width: 100px;
            height: 50px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            text-align: center;
        }
        .spinner .ball {
            width: 20px;
            height: 20px;
            background-color: #646464;
            border-radius: 50%;
            display: inline-block;
            -webkit-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
            animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
        }
        p {
            color: #646464;
            margin-top: 5px;
            font-family: sans-serif;
            letter-spacing: 3px;
            font-size: 10px;
        }
        @-webkit-keyframes motion {
            0% {
                -webkit-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
            }
            25% {
                -webkit-transform: translateX(-50px) scale(0.3);
                transform: translateX(-50px) scale(0.3);
            }
            50% {
                -webkit-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
            }
            75% {
                -webkit-transform: translateX(50px) scale(0.3);
                transform: translateX(50px) scale(0.3);
            }
            100% {
                -webkit-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
            }
        }
        @keyframes motion {
            0% {
                -webkit-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
            }
            25% {
                -webkit-transform: translateX(-50px) scale(0.3);
                transform: translateX(-50px) scale(0.3);
            }
            50% {
                -webkit-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
            }
            75% {
                -webkit-transform: translateX(50px) scale(0.3);
                transform: translateX(50px) scale(0.3);
            }
            100% {
                -webkit-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
            }
        }
    }
}
